<template>
  <a-row :gutter="25">
    <!--{{ "dataForm "  +  JSON.stringify(dataForm.dataTableInfo) }}-->
    <a-col :xxl="24" :lg="24" :md="24" :xs="24" v-if="showLoader">
      <sdCards>
        <a-spin size="small" style="position:relative; left: 45%;margin-top: 2rem;" />
      </sdCards>
    </a-col>
    <a-col :xxl="24" :lg="24" :md="24" :xs="24" v-if="!showLoader">
      <a-row :gutter="25" class="ml-2">
        <span class="event--title">{{ event.title }}</span
        ><br />
      </a-row>
      <a-row :gutter="25" class="ml-2 mt-2">
        <a-col :xxl="12" :lg="12" :md="12" :xs="24" class="card-fields">
          <span class="field-title">Fecha de inicio del evento</span><br />
          <p class="content">
            {{
              event.event_start_date ? `${formatDate(event.event_start_date)}, ${removeSeconds(event.event_start_hour)} hrs.` : 'No definida'
            }}
          </p>
        </a-col>
        <a-col :xxl="12" :lg="12" :md="12" :xs="24" class="card-fields">
          <span class="field-title">Fecha de finalización del evento</span><br />
          <p class="content">
            {{
              event.event_end_date ? `${formatDate(event.event_end_date)}, ${removeSeconds(event.event_end_hour)} hrs.` : 'No definida'
            }}
          </p>
        </a-col>
      </a-row>

      <tenplate v-if="!collapsed">
        <a-row :gutter="25" class="ml-2 mt-2">
          <a-col class="card-fields" :xxl="12" :lg="12" :md="24" :xs="24">
            <span class="field-title">Sedes</span>
            <a-col
              v-for="(sede, index) in getSedesData(event)"
              :key="sede"
              :xxl="24"
              :lg="24"
              :md="24"
              :xs="24"
              class="card-fields"
            >
              <span class="title-normal">#{{ index + 1 }}</span>
              <br />
              <div v-for="element in sede" :key="element">
                <div v-if="element.value != null">
                  <span class="">{{ element.label }}</span
                  ><br />
                  <span class="field-text-value">{{ element.value }}</span>
                </div>
              </div>
            </a-col>
          </a-col>
          <a-col :xxl="12" :lg="12" :md="12" :xs="24" class="card-fields">
            <span class="field-title">¿A quién va dirigido el evento?</span><br />
                <div class="content">
                  {{ aimedAt() }}
                </div>
          </a-col>
        </a-row>
        <br />

        <a-row :gutter="25" v-if="dynamicFormEvent != null && event.activities && !showRegisterParticipant && !showRegisterAssistant">
          <a-col :xxl="24" :lg="24" :md="24" :xs="24">
            <button class="button-normal" @click="showDynamicForm = !showDynamicForm">
              <span class="title-normal" v-if="!showDynamicForm">Contestar formulario</span>
              <span class="title-normal" v-else>Ocultar formulario</span>
            </button>
          </a-col>
        </a-row>
        <a-row :gutter='25' v-if='showRegisterParticipant || showRegisterAssistant'>
          <div class='form__buttons'>

            <button class='btn-fill-add' @click='handleRegisterClick(true)'
                    v-if='showRegisterAssistant'>
              <span class='title-normal'> Registrar asistentes </span>
            </button>
            <button class='btn-fill-add' @click='handleRegisterClick(false)'
                    v-if='showRegisterParticipant'>
              <span class='title-normal'> Registrar participantes </span>
            </button>

          </div>
        </a-row>
      </tenplate>
      <br />

    </a-col>
  </a-row>
  <div class="w-100 mt-2" style="background-color: #CCCCCC; height: 1px" />
  <a-row :gutter="25" class="ml-2 mt-2 justify-content-center">
    <sdFeatherIcons
      class="btn-collapse"
      :type="collapsed ? 'chevron-down' : 'chevron-up'"
      size="21"
      @click="collapsed = !collapsed"
    />
  </a-row>

</template>

<script>

import { formatDate, removeSeconds } from '@/utility/utility';

const SubEvent = {
  name: 'ValidationSubEventsDetailsComponent',
  components: {
  },
  emits: [],
  props: {
    event: null,
    parentID: null,
    hideLink: false,
  },
  watch: {
  },
  mounted() {
  },
  data() {
    return {
      showLoader: false,
      base_url: process.env.VUE_APP_BASE_URL,
      collapsed: true,
      sendFormLoading: false,

    };
  },
  methods: {
    getSedesData(event) {
      let fields = [];
      event.headquarters.forEach(element => {
        let sede = '';
        let type = '';
        if (element.type == 'face-to-face') {
          type = 'Presencial';
          sede = `${element.name}, ${element.street}, ${
            element.suburb != null ? element.suburb : element.other_suburb
          }, ${element.municipality}, ${element.postal_code}`;
        } else if (element.type == 'virtual') {
          type = 'Virtual';
          //sede = `${element.virtual_platform}, ${element.link}`;
          sede = `${element.virtual_platform}${this.hideLink ? '' :  ', '+element.link}`;
        } else {
          type = 'Ambas (vitual y presencial)';
          //sede = `${element.virtual_platform}, ${element.link}, ${element.name}, ${element.street}, ${
          sede = `${element.virtual_platform}${this.hideLink ? '' : ', '+element.link}. ${element.name}, ${element.street}, ${
            element.suburb != null ? element.suburb : element.other_suburb
          }, ${element.municipality}, ${element.postal_code}`;
        }
        fields.push([
          {
            label: type,
            value: sede,
          },
        ]);
      });

      return fields;
    },
  },

  async setup(props) {

    const aimedAt = () => {
      const educational = props.event.edu_can_register ? 'Estructura educativa' : '';
      const administrative = props.event.admin_can_register ? 'Personal en centro de trabajo administrativo' : '';
      const student = props.event.student_can_register ? 'Estudiantes' : '';
      const citizen = props.event.citizen_can_register ? 'Ciudadanos' : '';
      // const aimedAt = educational + ' ' + administrative + ' ' + student + ' ' + citizen;
      const aimedAt = [educational, administrative, student, citizen];
      return aimedAt.filter(val => !!val).join(' y ');
      // return aimedAt.trim().replace(/\s+/g, ' y ');
    };

    return { formatDate, aimedAt, removeSeconds };
  },
};
export default SubEvent;
</script>
<style scoped>
.button-normal {
  background: #3FA7E1;
  color: #fff;
  border-radius: 20px;
  border: none;
  padding: 8px 15px 8px 15px;
  cursor: pointer;
}

.card-title {
  padding: 1rem;
  border-left: 0.5rem solid #3fa7e1;
  background: #f8f8f8;
  border-top: 2px solid #e8e5e5;
  font-weight: bold !important;
}

.card {
  box-shadow: 0px 3px 6px #00000029;
  border-radius: 20px;
}

.card-fields {
  padding: 0 !important;
}

.field-title {
  font-weight: 600;
  font-family: 'Avenir Heavy';
  font-size: 14px;
}

.field-text-value {
  color: #7b868c;
  font-family: 'Avenir Roman';
  font-size: 14px;
}

.text-file {
  color: #e8394d;
}

.event--title {
  font-family: 'Nutmeg Bold';
  font-size: 18px;
  color: #404040;
}
.btn-collapse {
  background-color: rgba(63, 167, 225, 0.15);
  color: #3fa7e1;
  width: 32px;
  height: 32px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.btn-collapse:hover {
  cursor: pointer;
}
.form__buttons{
  padding: 32px 21px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 15px;
}
.btn-fill-add{
  border: 1px solid #9ED2EF;
  color: #ffffff;
  padding: 5px 15px 5px 15px;
  background: #3FA7E1;
  border-radius: 24px;
}

button:hover{
  cursor: pointer;
}


</style>
